<template>
  <b-overlay :show="isLoading">
    <b-row>
      <b-col>
        <h1>
          Users
        </h1>
      </b-col>
      <b-col cols="auto">
        <!-- Button -->
        <b-button class="mr-2 add-button" @click="addNew()">
          Add
        </b-button>
      </b-col>
    </b-row>
    <vuetable
      ref="customformstable"
      :api-mode="false"
      :fields="fields"
      :data="customForms"
      @vuetable:pagination-data="onPaginationData"
    >
      <template slot="actions" scope="props">
        <font-awesome-icon
          icon="edit"
          class="action-icon"
          data-toggle="tooltip"
          data-placement="top"
          title="Edit item"
          @click="edit(props.rowData)"
          ref="btnEdit"
        ></font-awesome-icon>
        <font-awesome-icon
          icon="trash"
          class="ml-2 action-icon"
          data-toggle="tooltip"
          data-placement="top"
          title="Remove item"
          @click="delete props.rowData"
        ></font-awesome-icon>
      </template>
    </vuetable>
    <vuetable-pagination
      ref="pagination"
      @vuetable-pagination:change-page="onChangePage"
    ></vuetable-pagination>
  </b-overlay>
</template>
<script>
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";

export default {
  components: {
    Vuetable,
    VuetablePagination,
  },
  data() {
    return {
      isLoading: false,
      fields: ["name", "actions"],
    };
  },
  computed: {
    customForms() {
      return this.$store.getters["CustomForms/all"];
    },
    customFormsInfo() {
      const { data, ...rest } = this.$store.getters["CustomForms/all"];
      return rest;
    },
  },
  methods: {
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.customformstable.changePage(page);
    },
    async doFetchAll() {
      this.isLoading = true;
      await this.$store.dispatch("CustomForms/fetchAll");
      this.isLoading = false;
    },
    edit(customForm) {
      this.$router.push({ name: "custom-form-detail", params: { id: customForm.id } });
    },
    add() {
      this.$router.push({ path: "custom-form-detail" });
    },
    async deleteForm(form) {
      this.isLoading = true;
      const isConfirmed = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to remove this form?`,
        {
          title: "Confirm delete",
          okVariant: "danger",
          centered: true,
        }
      );
      if (isConfirmed) {
        await this.$store.dispatch("CustomForms/delete", form.id);
        return this.doFetchAll();
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.doFetchAll();
  },
};
</script>
<style lang="scss">
$color-secondary: var(--secondary);

.action-icon:hover {
  color: $color-secondary;
  cursor: pointer;
}
</style>
